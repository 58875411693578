<template>
  <div class="container" v-if="show" v-loading="loading">
    <div class="closeBtn" @click="close">
      <img :src="require('@/assets/close_white.png')" alt="" />
    </div>
    <!-- <p class="decode-result">
      Last result: <b>{{ result }}</b>
    </p> -->

    <qrcode-stream
      :paused="paused"
      @detect="onDetect"
      @error="onError"
      @camera-on="resetValidationState"
    >
      <!-- 这是一个插槽 <div class="validation-success">This is a URL</div> -->
    </qrcode-stream>
  </div>
</template>

<script>
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";

export default {
  components: { QrcodeStream },

  data() {
    return {
      isValid: undefined,
      paused: false, //是否暂停调用摄像头
      result: null,
      show: false,
      methods: null,
      loading: false,
    };
  },
  computed: {},

  methods: {
    open(omethods) {
      this.show = true;
      this.methods = omethods;
      this.loading = true;
    },

    // 错误事件
    onError: console.error,

    // 摄像头调用事件
    resetValidationState() {
      // console.log("diaoyong");
      this.loading = false;
      this.isValid = undefined;
    },

    // 识别成功事件
    async onDetect([firstDetectedCode]) {
      this.result = firstDetectedCode.rawValue;
      this.methods(firstDetectedCode.rawValue);
      this.close();
      //   this.paused = true;

      //   // pretend it's taking really long
      //   await this.timeout(3000);
      //   this.isValid = this.result.startsWith("http");

      //   // some more delay, so users have time to read the message
      //   await this.timeout(2000);
      //   this.paused = false;
    },

    // 暂停多少秒
    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },

    close() {
      this.show = false;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0.8);
  z-index: 4;
}
.closeBtn {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 3;
  background-color: #000000;
  border-radius: 50%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
  }
}
.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  color: black;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: green;
}
.validation-failure {
  color: red;
}
</style>
