<template>
  <div class="pageCon" v-loading="loading">
    <headBackBar :iCustomizdde="true" :icon="true">
      <template v-slot:left-icon>
        <van-icon name="arrow-left" @click="$router.go(-1)" />
      </template>
      <template #default>
        <span>
          {{ $fanyi("优惠券兑换") }}
        </span>
      </template>
      <!--设置内容选项区域 内容区域 -->
    </headBackBar>
    <div class="conponCon">
      <div class="conhhh">
        <div class="inputRow">
          <div class="labelBox">{{ $fanyi("请填写兑换码") }}:</div>
          <div class="inputBox">
            <input
              type="text"
              v-model="onformdata.code"
              @input="trimInput_code"
            />
            <div class="imgIcon">
              <img
                :src="require('@/assets/user-img/scanning.png')"
                alt=""
                @click="scanCode"
              />
            </div>
          </div>
        </div>
        <div class="inputRow">
          <div class="labelBox">{{ $fanyi("请填写到会登记的email地址") }}:</div>
          <div class="inputBox">
            <input
              type="text"
              v-model="onformdata.email"
              @input="trimInput_email"
              @change="isValidEmail"
            />
          </div>
        </div>
        <button type="primary" class="confimButton" @click="submit">
          {{ $fanyi("确定") }}
        </button>
      </div>
    </div>
    <qrCode ref="QrCode" />
  </div>
</template>
<script setup>
import qrCode from "@/components/qrCode/index.vue";
import headBackBar from "@/components/headGoBack/index.vue";
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data ----------------------------------------
const onformdata = ref({
  code: "",
  email: "",
});
const loading = ref(false);
//------------------------ methods -------------------------------------
const scanCode = () => {
  proxy.$refs.QrCode.open((res) => {
    onformdata.value.code = res;
  });
};

const trimInput_code = () => {
  onformdata.value.code = onformdata.value.code.toUpperCase();
  onformdata.value.code = proxy.$fun.japanStrReplace(onformdata.value.code);
  onformdata.value.code = onformdata.value.code.replace(/[^a-zA-Z0-9_-]/g, "");
  onformdata.value.code = onformdata.value.code.trim();
};

const trimInput_email = () => {
  onformdata.value.email = onformdata.value.email.trim();
};

const isValidEmail = () => {
  // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // let isEmail = emailPattern.test(onformdata.value.email);
  // if (!isEmail) proxy.$message.error("邮箱格式不正确，请输入正确的邮箱格式");
  // return isEmail;
  return true;
};

const submit = () => {
  let isEmail = isValidEmail();
  if (!isEmail) return false;
  loading.value = true;
  proxy.$api.giftCodeExchange(onformdata.value).then((res) => {
    ////console.log('事件名',res)
    loading.value = false;
    if (res.code != 0) return proxy.$message.error(proxy.$fanyi(res.msg));
    //接下来的操作
    proxy.$message.success(proxy.$fanyi(res.msg));
  });
};
//------------------------ pageLoad ------------------------------------
defineExpose({});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.pageCon {
  background-color: #f2f2f2;
  height: 100vh;
}
.conponCon {
  padding: 30px;
  border-radius: 6px;
  .conhhh {
    padding: 50px 30px;
    background-color: white;
  }
  .inputRow {
    margin-bottom: 50px;
    .labelBox {
      font-size: 28px;
      margin-bottom: 30px;
    }
    .inputBox {
      height: 70px;
      background: #ffffff;
      border: 1px solid #dfdfdf;
      border-radius: 6px;
      padding: 10px 20px 10px 24px;
      display: flex;
      align-items: center;
      // color: #999999;
      &:focus {
        border-color: #b4272b;
      }
      input {
        border: none;
        font-size: 28px;
        padding: 0 10px 0 0;
      }
      .imgIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  .confimButton {
    margin: auto;
    width: 7.6rem;
    height: 0.93333rem;
    background: #b4272b;
    border-radius: 0.08rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.37333rem;
    font-weight: 400;
    color: #ffffff;
    margin-top: 80px;
  }
}

.reader-box {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.reader {
  width: 540px;
  height: 540px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
