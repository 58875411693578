<!-- 仓库运输服务 -->
<template>
  <div style="background: #f6f6f6">
    <!-- 头部 -->
    <headGoBack :iCustomizdde="true">
      <template #default>
        <span> 倉庫納品サービス・転送サービス </span>
      </template>
    </headGoBack>
    <!-- --------------------- -->
    <div class="bg1 dip">
      <div class="bg1Title">倉庫納品サービス・転送サービス</div>
    </div>
    <!-- --------------------- -->
    <div class="serviceIntroduction whiteModel dip fdcolmun">
      <h3>倉庫納品サービス</h3>
      <p>
        倉庫納品サービスは、<br />
        商品の仕入れからAmazon FBA倉庫、<br />
        楽天RSLへの配送までを一貫してサポートしています。
      </p>
      <div class="warehouseCategory dip">
        <div class="imgBox">
          <img
            class="img1"
            src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202412/6765297765b7b.png"
            alt=""
          />
        </div>

        <span>&</span>
        <div class="imgBox">
          <img
            class="img2"
            src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202412/676529651ad5e.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- --------------------- -->
    <div class="usageProcess dip fdcolmun">
      <h3>ご利用の流れ</h3>
      <div class="usageProcessItem">
        <div class="numBox">01</div>
        <div class="spanBox">新規会員登録</div>
      </div>
      <div class="usageProcessItem">
        <div class="numBox">02</div>
        <div class="spanBox">注文書を提出</div>
      </div>
      <div class="usageProcessItem">
        <div class="numBox">03</div>
        <div class="spanBox">見積提出</div>
      </div>
      <div class="usageProcessItem">
        <div class="numBox">04</div>
        <div class="spanBox">注文支払い</div>
      </div>
      <div class="usageProcessItem">
        <div class="numBox">05</div>
        <div class="spanBox">買い付け&商品を弊社倉庫へ配送</div>
      </div>
      <div class="usageProcessItem">
        <div class="numBox">06</div>
        <div class="spanBox">配送依頼を提出</div>
      </div>
      <div class="usageProcessItem">
        <div class="numBox">07</div>
        <div class="spanBox">商品ラベ ル送付</div>
      </div>
      <div class="usageProcessItem">
        <div class="numBox">08</div>
        <div class="spanBox">送料の見積</div>
      </div>
      <div class="usageProcessItem">
        <div class="numBox">09</div>
        <div class="spanBox">送料の支払い&配送ラベルの送付</div>
      </div>
      <div class="usageProcessItem">
        <div class="numBox">10</div>
        <div class="spanBox">日本へ出荷</div>
      </div>
      <div class="usageProcessItem">
        <div class="numBox">11</div>
        <div class="spanBox">受取</div>
      </div>
    </div>
    <!-- --------------------- -->
    <div class="whiteModel cost dip fdcolmun">
      <h3>費用</h3>
      <div class="logisticsCategory dip">
        <div class="item">
          <img
            src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202412/6766524d58d87.png"
            alt=""
          />

          <button @click="$fun.routerToPage('/internationalLogistics')">
            ページ参照
          </button>
        </div>

        <div class="item">
          <img
            src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202412/67665227c5edd.png"
            alt=""
          />
          <button @click="$fun.routerToPage('/option')">ページ参照</button>
        </div>
      </div>
    </div>
    <!-- --------------------- -->
    <div class="mattersNeedingAttention dip fdcolmun">
      <h3>注意事項</h3>
      <div class="pBox flex fdcolmun">
        <p>
          1.商品ラベル規格：<span class="red">A4版24面66*33.9㎜</span> ；<br />
          FBA配送ラベルの規格： <span class="red">A4版6面99*105㎜</span>
        </p>
        <p>
          2.通関時必要の為、配送ラベル提出時に販売商品ページの
          画像も一緒に提供してください。在庫切れの商品がある場 合、在庫管
          理と納品プランの共有をお願いします。
        </p>
      </div>
    </div>
    <!-- --------------------- -->
    <div class="transferService whiteModel dip fdcolmun">
      <h3>転送サービス</h3>
      <p>
        転送サービスはお客様自身でご購入いただいた商品を <br />
        弊社中国倉庫より国際配送を請け負うサービスとなります。
      </p>
    </div>
    <!-- --------------------- -->
    <div class="usageProcess dip fdcolmun">
      <h3>ご利用の流れ</h3>
      <div class="usageProcessItem">
        <div class="numBox">01</div>
        <div class="spanBox">新規会員登録</div>
      </div>
      <div class="usageProcessItem">
        <div class="numBox">02</div>
        <div class="spanBox">転送サービス利用の旨を連絡</div>
      </div>
      <div class="usageProcessItem">
        <div class="numBox">03</div>
        <div class="spanBox">転送住所設定</div>
      </div>
      <div class="usageProcessItem">
        <div class="numBox">04</div>
        <div class="spanBox">商品を弊社倉庫へ配送</div>
      </div>
      <div class="usageProcessItem">
        <div class="numBox">05</div>
        <div class="spanBox">配送依頼を提出</div>
      </div>
      <div class="usageProcessItem">
        <div class="numBox">06</div>
        <div class="spanBox">送料の見積</div>
      </div>
      <div class="usageProcessItem">
        <div class="numBox">07</div>
        <div class="spanBox">送料の支払い</div>
      </div>
      <div class="usageProcessItem">
        <div class="numBox">08</div>
        <div class="spanBox">日本へ出荷</div>
      </div>
      <div class="usageProcessItem">
        <div class="numBox">09</div>
        <div class="spanBox">受取</div>
      </div>
    </div>
    <!-- --------------------- -->
    <div class="costChart whiteModel dip fdcolmun">
      <h3>費用</h3>
      <div class="scrollBox" style="width: 100%">
        <div style="display: inline-block">
          <table class="listTableHtmlBox costChartConTable">
            <tr>
              <th rowspan="2" class="width150">プラン</th>
              <th rowspan="2" class="width300">手数料</th>
              <th colspan="2">検品費用（必要時）</th>
              <th colspan="2">梱包手数料</th>
              <th rowspan="2" class="width200">オプション</th>
              <th rowspan="2" class="width200">国際料金</th>
              <th rowspan="2" class="width120">
                無料 <br />
                保管 <br />
                期限
              </th>
              <th rowspan="2" class="width200">
                インボイス <br />
                作成費用
              </th>
            </tr>
            <tr>
              <th class="width200">簡易検品</th>
              <th class="width200">有料検品</th>
              <th class="width200">
                段ボール <br />
                入れ替え <br />（標準サイズ）
              </th>
              <th class="width200">
                元の段ボール <br />
                で発送
              </th>
            </tr>
            <tr>
              <td>転送月費 プラン</td>
              <td>30,000円/月</td>
              <td rowspan="2">商品代2%</td>
              <td rowspan="2">
                ①30元以上： 商品代金6% <br />
                ※通常代行定 額会員の場 合、商品代金 5％ <br /><br />
                ②30元以下： (1)単価15元以 下：1.5元/点 <br />
                (2)単価30元以 下：2元/点
              </td>
              <td rowspan="2">15元/箱</td>
              <td rowspan="2">10元/箱</td>
              <td rowspan="2">
                <span class="aLink red" @click="$fun.routerToPage('/option')"
                  >ページ参照</span
                >
              </td>
              <td rowspan="2">
                <span
                  class="aLink red"
                  @click="$fun.routerToPage('/internationalLogistics')"
                  >ページ参照</span
                >
              </td>
              <td rowspan="2">7日間</td>
              <td>無料</td>
            </tr>
            <tr>
              <td>回数転送 プラン</td>
              <td>
                ①商品代金5000元以 上：商品代3% <br /><br />
                ②商品代金5000元未 満：150元/回
              </td>
              <td>無料</td>
            </tr>
          </table>
        </div>
        <!-- //marginBox兼容safari的右边margin塌陷问题用 -->
        <div class="marginBox"></div>
      </div>
    </div>
    <!-- --------------------- -->
    <div class="mattersNeedingAttention dip fdcolmun">
      <h3>注意事項</h3>
      <div class="pBox flex fdcolmun">
        <p>
          <span class="pTitle"> 1. 破損について</span>
          無料検品・有料検品の責任の範囲内で、明らかな破損や異常が確認された場合、国際発送前にご連絡致します。但し、返品や交換の交渉は弊社購入ではございませんので、全てお客様ご自身で行
          って頂く必要があります。
        </p>
        <p>
          <span class="pTitle">2. 紛失について</span>
          弊社受取前、紛失が発生した場合、全てご自身で発送元、若しくは中国内運送会社にご相談下さい。万が一、弊社倉庫に到着後の紛失の場合、弊社が賠償させて頂きます。
        </p>
        <p>
          <span class="pTitle"> 3. 禁輸品について </span>
          コピー品等日本税関輸入禁止の商品、各運送業者の自主規制として取り扱わない商品、また弊社にてお取り扱いをお断りさせて
          頂いている商品は日本へ出荷手配不可となっておりますので、必ず
          事前に出荷の可否をご確認下さい。また、禁輸品によって、弊社が損害を被った場合、損害賠償を請求させて頂く場合があります。また、コピー品の場合、国際運送会社より、税関滅却費用、ペ
          ナルティ費用、倉庫費用、特別対応事務手数料、内点費用、仕分け費用、再申告費用等、追加費用が発生した場合、国際運送会社とお客様の間でお支払い下さい。
          禁輸品に該当する為、通関できず、商品が受け取れない場合、商品代その他含めて弊社は責任を負い兼ねますのでご注意くださ
          い。
        </p>
        <p>
          <span class="pTitle"> 4. 規制品について </span>
          バッテリー付き商品や食品衛生法・薬機法に関わる商品の場合、事前にご相談下さい。相談無き場合、購入・転送後、通関出来ずに廃棄処分となる場合があります。
        </p>
        <p>
          <span class="pTitle"> 5. 保管費用について </span>
          商品到着後7日以内の保管は無料です。7日以降は1m³毎に1日5元の保管費用が発生します。1m³に満たない場合は、1m³で計算されます。（1点でも1m³換算）
        </p>
        <p>
          <span class="pTitle"> 6. その他 </span>
          商品に関税や消費税が課される場合があります。これらの費用はお客様のご負担となります。商品の内容（品目、数量、価格）
          は正確にご提供ください。不正確な申告は税関でのトラブルや罰則
          の原因となります。また、弊社も各関係機関よりペナルティを受けた場合は、相当の損害額を請求させて頂きます。
        </p>
      </div>
    </div>
    <!-- 底部分类 -->
    <foot></foot>
  </div>
</template>
<script setup>
import foot from "../../components/footBar/index.vue";
import headGoBack from "@/components/headGoBack";
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
//------------------------ methods -------------------------------------
//------------------------ pageLoad -------------------------------------
defineExpose({});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.whiteModel {
  background-color: white;
}
.bg1 {
  height: 450px;
  background-size: 100% 100%;
  background-image: url("https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202412/676524c7d9a0a.png");
  .bg1Title {
    height: 35px;
    font-weight: 600;
    font-size: 36px;
    color: #ffffff;
  }
}
.red {
  color: #b4272b;
}

h3 {
  font-weight: 600;
  font-size: 30px;
  color: #333333;
  line-height: 38px;
  margin-bottom: 30px;
}

.serviceIntroduction {
  padding: 55px 0 60px;
  //   h3 {

  //   }
  p {
    font-weight: 400;
    font-size: 24px;
    line-height: 1.2;
    color: #333333;
    margin-bottom: 30px;
    text-align: center;
  }
  .warehouseCategory {
    gap: 35px;
    .imgBox {
      width: 203px;
      text-align: right;
    }
    .img1 {
      width: 160px;
      height: 54px;
    }
    .img2 {
      width: 203px;
      height: 54px;
    }
    span {
      font-family: Arial;
      font-weight: 400;
      font-size: 48px;
      color: #333333;
    }
  }
}

.usageProcess {
  padding: 55px 0 60px;
  h3 {
    margin-bottom: 57px;
  }
  .usageProcessItem {
    position: relative;
    @extend .dip;
    flex-direction: column;

    .spanBox {
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0px 5px 8px 0px rgba(90, 90, 90, 0.1);
      border-radius: 4px;
      min-width: 200px;
      padding: 11px 25px;
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      z-index: 1;
      position: relative;
    }
    .numBox {
      font-family: MiSans;
      font-weight: bold;
      font-size: 36px;
      color: #b4272b;
      font-style: italic;
      position: absolute;
      top: -18px;
      left: 13px;
    }
  }
  .usageProcessItem::after {
    width: 3px;
    height: 50px;
    background-image: url("https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202412/67665281ea8b5.png");
    margin: 16px 0;
    display: block;
    background-size: 100%;
    content: " ";
  }
  .usageProcessItem:last-child::after {
    display: none;
  }
}

.cost {
  padding: 55px 0 60px;
  h3 {
    margin-bottom: 38px;
  }
  .logisticsCategory {
    gap: 50px;
    .item {
      @extend .dip;
      flex-direction: column;
      img {
        width: 260px;
        height: 200px;
        margin-bottom: 12px;
      }
      button {
        width: 260px;
        height: 60px;
        background: #b4272b;
        border-radius: 6px;
        font-weight: 400;
        font-size: 20px;
        color: #ffffff;
        &:active {
          background-color: #a42428;
        }
      }
    }
  }
}

.mattersNeedingAttention {
  padding: 55px 0 75px;
  .pBox {
    width: 630px;
    gap: 25px;
    p {
      font-size: 24px;
      line-height: 1.24;
      * {
        font-size: 24px;
      }
      .pTitle {
        font-weight: 600;
        display: block;
      }
    }
  }
}

.transferService {
  @extend .serviceIntroduction;
  p {
    margin-bottom: 0;
  }
  padding-bottom: 55px;
}

.costChart {
  padding: 55px 0 60px;
  .scrollBox {
    padding-bottom: 2px;
    white-space: nowrap;
  }
  //marginBox兼容safari的右边margin塌陷问题用
  .marginBox {
    display: inline-block;
    width: 1px;
    height: 100%;
  }
  .costChartConTable {
    width: max-content;
    margin: 0 50px;
    th {
      background-color: #f0f0f0;
      border-color: #dfdfdf;
      height: 80px;
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      padding: 5px 0;
      line-height: 1.3;
    }
    td {
      padding: 25px 20px;
      font-size: 24px;
      height: 200px;
      line-height: 1.2;
      border-color: #dfdfdf;

      * {
        font-size: 24px;
      }
    }
  }
  .width150 {
    width: 150px;
  }
  .width300 {
    width: 300px;
  }
  .width200 {
    width: 200px;
  }
  .width120 {
    width: 120px;
  }
}
</style>
